import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import qs from 'qs';

import { productLineService } from '../../service';

import HeaderParamsList from '../../../components/HeaderParamsList/HeaderParamsList';
import MediaObject from '../../../MediaObject/components/MediaObject';
import Spinner from '../../../components/Spinner/Spinner';
import Table from '../../../components/Table/Table';
import TableHeader from '../../../components/Table/TableHeader';
import PaginationWithSearch from '../../../components/Pagination/PaginationWithSearch';
import ActionsIconTable from '../../../components/ActionsIconTable/ActionsIconTable';
import Filters from './Filters';
import * as productLineConstants from '../../constants';
import * as brandConstants from '../../../Brand/constants';

import '../../styles/List.scss';

const { API_PATH, PATH } = productLineConstants;
const { PATH: brandPath } = brandConstants;

const defaultParams = {
  itemsPerPage: 10,
  page: 1,
  order: {
    name: 'asc',
  }
};

const List = (props) => {
  const { history } = props;
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const [ productLines, setProductLines ] = useState([]);
  const [ urlsPagination, setUrlsPagination ] = useState({});
  const [ total, setTotal ] = useState(0);
  const search = decodeURIComponent(history.location.search);
  const params = search === "" ? defaultParams : qs.parse(search, { ignoreQueryPrefix: true });
  const [ searchParams, setSearchParams ] = useState(params);
  const order = !!searchParams.order ? searchParams.order : defaultParams.order;
  const [ itemsPerPage, setItemsPerPage ] = useState(!!searchParams.itemsPerPage ? searchParams.itemsPerPage : defaultParams.itemsPerPage);

  const handleSubmit = (newFilters) => {
    let searchParamsString = '';
    const newFiltersQS = {
      page: defaultParams.page,
      itemsPerPage: !!searchParams.itemsPerPage ? searchParams.itemsPerPage : defaultParams.itemsPerPage,
      order: !!searchParams.order ? searchParams.order : defaultParams.order,
      ...newFilters,
    };
    searchParamsString = qs.stringify(newFiltersQS, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString)});
    setSearchParams(newFiltersQS);
  };

  const handleChangeItemsPerPage = (e) => {
    setItemsPerPage(e.target.value);

    const newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams.itemsPerPage = parseInt(e.target.value, 10);
    setSearchParams(newSearchParams);

    let searchParamsString = '';
    searchParamsString = qs.stringify(newSearchParams, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString)});
  };

  const handleChangeOrder = (column, direction) => {
    const newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams.order = {
      [column]: direction
    };
    setSearchParams(newSearchParams);

    let searchParamsString = '';
    searchParamsString = qs.stringify(newSearchParams, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString)});
  };

  const handleDelete = async (itemId) => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      try {
        setLoading(true);
        await productLineService._delete(itemId);
        const endpoint = `${API_PATH}/`;
        if (!searchParams.itemsPerPage) {
          searchParams.itemsPerPage = defaultParams.itemsPerPage;
        }
        if (!searchParams.order) {
          searchParams.order = defaultParams.order;
        }
        const newProductLines = await productLineService.getAll(endpoint, searchParams);
        setError('');
        setProductLines(newProductLines["hydra:member"]);
        setUrlsPagination(newProductLines["hydra:view"]);
        setTotal(newProductLines["hydra:totalItems"]);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setProductLines([]);
        setUrlsPagination({});
        setTotal(0);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();

    if (mounted) {
      const search = decodeURIComponent(history.location.search);
      if (qs.stringify(searchParams, { addQueryPrefix: true }) !== search) {
        setSearchParams(qs.parse(search, { ignoreQueryPrefix: true }))
      } else {
        setLoading(true);
        const endpoint = `${API_PATH}/`;
        if (!searchParams.itemsPerPage) {
          searchParams.itemsPerPage = defaultParams.itemsPerPage;
        }
        if (!searchParams.order) {
          searchParams.order = defaultParams.order;
        }
        productLineService.getAll(endpoint, searchParams, {
          signal: controller.signal
        }).then((newProductLines) => {
          setError('');
          setProductLines(newProductLines["hydra:member"]);
          setUrlsPagination(newProductLines["hydra:view"]);
          setTotal(newProductLines["hydra:totalItems"]);
          setLoading(false);

          if (history.location.hash) {
            const id = history.location.hash.replace('#', '');
            if (!!id) {
              const found = newProductLines["hydra:member"].find((newProductLine) => newProductLine.id.toString() === id);
              if (found) {
                const ofTop = document.getElementById(id).offsetTop;
                document.getElementById('block-table-product-lines').scrollTop = ofTop - 65;
              }
            }
          }
        }).catch((e) => {
          setError(!!e && !!e.message ? e.message : e);
          setProductLines([]);
          setUrlsPagination({});
          setTotal(0);
          setLoading(false);
        });
      }
    }

    return () => {
      mounted = false;
      controller?.abort();
    }
  }, [searchParams, history.location.search, history.location.hash])

  //TODO: products ?
  const dataBlock = productLines && productLines.map((item) => {
    let brandLink = null;
    if (!!item.brand && !!item.brand.name && !!item.brand.id) {
      brandLink = <Link to={`${brandPath}show/${item.brand.id}`}>{item.brand.name}</Link>;
    }
    return (
      <tr key={item["id"]} id={item.id}>
        <th scope="row">
          <Link to={`show/${encodeURIComponent(item["id"])}`}>
            {item["id"]}
          </Link>
        </th>
        <td>{item["name"]}</td>
        <td>{ brandLink }</td>
        <td>
          <div className="center-logo">
            <MediaObject logo={item["logo"]} />
          </div>
        </td>
        <ActionsIconTable
          item={item}
          onDelete={handleDelete}
        />
      </tr>
    );
  });

  const tableHeaders = [
    {
      field: 'id',
      sortable: false,
      translation: 'Id',
    },
    {
      field: 'name',
      sortable: true,
      translation: 'Nom',
    },
    {
      field: 'brand',
      sortable: false,
      translation: 'Marque',
    },
    {
      field: 'logo',
      sortable: false,
      translation: 'Image',
      className: 'text-center',
    },
    {
      field: 'actions',
      sortable: false,
      translation: 'Actions',
      colSpan: 3
    },
  ];

  return (
   <div className="list-product-lines d-flex flex-column h-100 mh-90vh justify-content-between">
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      <HeaderParamsList
        itemsPerPage={itemsPerPage}
        page={searchParams.page}
        path={PATH}
        total={total}
        onChangeItemsPerPage={handleChangeItemsPerPage}
      />

      <div className="mb-3 overflow-hidden block-table-filters">
        <Filters
          filters={searchParams}
          onSubmit={handleSubmit}
        />

        <div id="block-table-product-lines" className="w-100 mw-100 overflow-auto scroll-custom mb-3">
          {
            loading
              ?
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <Spinner />
                </div>
              :
                (
                  <Table>
                    <TableHeader
                      headers={tableHeaders}
                      order={order} 
                      onChangeOrder={handleChangeOrder}
                    />
                    <tbody>
                      { 
                        dataBlock.length === 0
                          ?
                            (<tr>
                              <td colSpan={10}>
                                <div className="d-flex justify-content-center align-items-center minh-50vh">
                                  <span>Pas de résultat.</span>
                                </div>
                              </td>
                            </tr>)
                          :
                            dataBlock
                      }
                    </tbody>
                  </Table>
                )
          }
        </div>
      </div>
      <PaginationWithSearch
        view={urlsPagination} 
        filters={searchParams}  
      />
    </div>
  );
}

export default List;
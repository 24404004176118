import React from 'react';

import Button from '../../components/Button/Button';
import InputSearchCatalog from '../../components/Input/InputSearchCatalog';
import Spinner from '../../components/Spinner/Spinner';
import { default as FormComponent } from '../../components/Form/Form';

const Form = (props) => {
  const { className, loading, values, onChange, onChangeNameField, onSubmit } = props;

  const handleChangeCatalog = (field, value) => {
    onChangeNameField(field, value)
  };

  const handleRemoveCatalog = () => {
    onChangeNameField('catalog', '')
  };

  let createdAtDisplay = undefined;
  if (!isNaN(Date.parse(values.createdAt))) {
    const createdAtDate = new Date(values.createdAt);
    createdAtDisplay = 'Classification créé le : ' + createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAtDisplay = undefined;
  if (!isNaN(Date.parse(values.updatedAt))) {
    const updatedAtDate = new Date(values.updatedAt);
    updatedAtDisplay = 'Mis à jour le : ' + updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }
  
  return (
    <FormComponent onSubmit={onSubmit} className={className}>
      {
        loading
          ?
            <Spinner className="d-flex align-items-center h-100 justify-content-center" />
          :
            <>
              <div className="form-group">
                <label
                  htmlFor="classification_name"
                  className="form-control-label"
                >
                  Nom
                </label>
                <input
                  type="text"
                  required
                  id='classification_name'
                  name="name"
                  className="form-control"
                  value={values.name}
                  onChange={onChange}
                />
                {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
              </div>

              <InputSearchCatalog
                value={typeof values.catalog === 'string' ? values.catalog : (values.catalog && values.catalog.label)}
                onChange={handleChangeCatalog}
                onSelect={handleChangeCatalog}
                onClear={handleRemoveCatalog}
                field='catalog'
              />

              <div className="col-md-12 mb-3">
                <div>{ createdAtDisplay }</div>
                <div>{ updatedAtDisplay }</div>
              </div>

              <Button type="submit" className="btn-secondary" onClick={onSubmit}>
                Enregistrer
              </Button>
            </>
      }
      
    </FormComponent>
  );
};

export default Form;
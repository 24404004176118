import React from "react";
import { Route } from "react-router-dom";
import List from "../Product/components/List/List.jsx";
import Imports from "../Product/components/Imports/Imports.jsx";
import Create from "../Product/components/Create.jsx";
import Edit from "../Product/components/Edit.jsx";
import Show from "../Product/components/Show.jsx";

const routes = [
  <Route path="/products/create" component={Create} exact key="create" />,
  <Route path="/products/edit/:id" component={Edit} exact key="edit" />,
  <Route path="/products/show/:id" component={Show} exact key="show" />,
  <Route path="/products/imports/" component={Imports} exact key="imports" />,
  <Route path="/products/" component={List} exact strict key="list" />,
];

export default routes;
import React from 'react';

import Tabs from '../components/Tabs/Tabs';
import Tab from '../components/Tabs/Tab';

import { default as ListBanners } from './Banner/components/List/List';

const Page = (props) => {
  const { history } = props;

  return (
    <div className="d-flex flex-column h-100 mh-90vh justify-content-between">
      <Tabs activeId="banners" isLoading={false}>
        <Tab title="Bandeaux" id="banners">
          <ListBanners history={history} />
        </Tab>
        <Tab title="Promo" id="promotions">
          <div className="d-flex flex-column h-100 mh-90vh justify-content-between">Promo</div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Page;
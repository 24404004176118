import React, { useState } from 'react';

import ExpansionPanelFilters from '../../../components/ExpansionPanel/ExpansionPanelFilters';
import InputSearchCategory from '../../../components/Input/InputSearchCategory';
import InputSearchMultipleClassification from '../../../components/Input/InputSearchMultipleClassification';

import '../../styles/Filters.scss';

const Filters = (props) => {
  const { filters, onSubmit } = props;
  const [ name, setName ] = useState(!!filters.name ? filters.name : '');
  const [ classifications, setClassifications ] = useState(!!filters.classifications ? filters.classifications : []);
  const [ parentCategory, setParentCategory ] = useState(!!filters.parentCategory ? filters.parentCategory : '');

  const handleSubmit = () => {
    const filters = {};

    if (name !== '') Object.assign(filters, { name: name.trim() });
    if (classifications.length > 0) Object.assign(filters, { classifications });
    if (parentCategory !== '') Object.assign(filters, { parentCategory });

    onSubmit(filters);
  };

  const handleChangeClassifications = (field, value) => {
    const newClassifications = [...classifications];

    if (typeof value === 'object') {
      newClassifications.push(value);
      setClassifications(newClassifications)
    }
  };

  const handleClearClassifications = () => {
    setClassifications([]);
  };

  const handleRemoveClassifications = (field, value) => {
    let newClassifications = [ ...classifications ];

    newClassifications = newClassifications.filter((classification) => classification.value !== value.value);

    setClassifications(newClassifications);
  };

  const handleClear = () => {
    if (name !== '') setName('');
    if (classifications.length > 0) setClassifications([]);
    if (parentCategory !== '') setParentCategory('');

    onSubmit({});
  };

  return (
    <ExpansionPanelFilters 
      title='Filtres'
      active={false}
      onSubmit={handleSubmit}
      onClear={handleClear}
      className="filters-categories"
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="form-group">
              <label
                htmlFor="category_name"
                className="form-control-label"
              >
                Nom
              </label>
              <input
                type="text"
                id='category_name'
                name="name"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
            </div>
          </div>

          <div className="col-xs-12 col-md-4">
            <InputSearchCategory
              value={typeof parentCategory === 'string' ? parentCategory : (parentCategory && parentCategory.label)}
              onChange={(field, value) => setParentCategory(value)}
              onSelect={(field, value) => setParentCategory(value)}
              onClear={() => setParentCategory('')}
              field='parent'
              label="Catégorie parente"
              categoryId={parentCategory.value}
            />
          </div>

          <div className="col-xs-12 col-md-4">
            <InputSearchMultipleClassification
              //values={typeof values.classification === 'string' ? values.classification : (values.classification && values.classification.label)}
              values={classifications}
              onChange={handleChangeClassifications}
              onSelect={handleChangeClassifications}
              onClear={handleClearClassifications}
              onRemove={handleRemoveClassifications}
              field='classifications'
            />
          </div>
        </div>
      </div>
    </ExpansionPanelFilters>
  );
};

export default Filters;
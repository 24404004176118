import React from 'react';

import Button from '../../components/Button/Button';
import InputSearchBrand from '../../components/Input/InputSearchBrand';
import InputSearchLaboratory from '../../components/Input/InputSearchLaboratory';
import Spinner from '../../components/Spinner/Spinner';
import { default as FormComponent } from '../../components/Form/Form';

import '../styles/Form.scss';

const Form = (props) => {
  const { className, loading, values, onChange, onChangeNameField, onSubmit } = props;

  const handleDeleteLogo = () => {
    onChangeNameField('logo', null);
  };

  const handleChangeLogo = (event) => {
    onChangeNameField('logo', event.target.files[0]);
  };

  const handleChangeLaboratory = (field, value) => {
    onChangeNameField(field, value)
  };

  const handleRemoveLaboratory = () => {
    onChangeNameField('laboratory', '')
  };

  const handleChangeBrand = (field, value) => {
    onChangeNameField(field, value)
  };

  const handleRemoveBrand = () => {
    onChangeNameField('brand', '')
  };

  let createdAtDisplay = undefined;
  if (!isNaN(Date.parse(values.createdAt))) {
    const createdAtDate = new Date(values.createdAt);
    createdAtDisplay = 'Gamme créée le : ' + createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAtDisplay = undefined;
  if (!isNaN(Date.parse(values.updatedAt))) {
    const updatedAtDate = new Date(values.updatedAt);
    updatedAtDisplay = 'Mis à jour le : ' + updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }
  
  return (
    <FormComponent onSubmit={onSubmit} className={className}>
      {
        loading
          ?
            <Spinner className="d-flex align-items-center h-100 justify-content-center" />
          :
            <>
              <div className="form-group">
                <label
                  htmlFor="product_line_name"
                  className="form-control-label"
                >
                  Nom
                </label>
                <input
                  type="text"
                  required
                  id='product_line_name'
                  name="name"
                  className="form-control"
                  value={values.name}
                  onChange={onChange}
                />
                {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
              </div>

              <div className="form-group">
                <label
                  htmlFor="product_line_logo"
                  className="form-control-label"
                >
                  Image
                </label>
                <input
                  type="file"
                  id='product_line_logo'
                  name="logo"
                  className="form-control"
                  //value={values.logo}
                  onChange={handleChangeLogo}
                />
                {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
                {(values.logo instanceof File || (!!values.logo && !!values.logo.url)) && (
                  <div className="img-thumbnail mt-3 text-center">
                    <img className="mx-auto my-auto img-fluid img-thumbnail mb-3" alt='product_line-logo' src={
                      (values.logo instanceof File
                        ? URL.createObjectURL(values.logo)
                        : (
                          !!values.logo && !!values.logo.url 
                            ? values.logo.url
                            : ''
                          )
                        )
                    } />
                    <div>
                      <Button className="btn-error btn-delete mb-3" onClick={() => handleDeleteLogo()}>Supprimer</Button>
                    </div>
                  </div>
                )}
              </div>

              <InputSearchLaboratory
                value={typeof values.laboratory === 'string' ? values.laboratory : (values.laboratory && values.laboratory.label)}
                onChange={handleChangeLaboratory}
                onSelect={handleChangeLaboratory}
                onClear={handleRemoveLaboratory}
                field='laboratory'
              />

              <InputSearchBrand
                value={typeof values.brand === 'string' ? values.brand : (values.brand && values.brand.label)}
                onChange={handleChangeBrand}
                onSelect={handleChangeBrand}
                onClear={handleRemoveBrand}
                field='brand'
                laboratory={values.laboratory}
              />

              <div className="col-md-12 mb-3">
                <div>{ createdAtDisplay }</div>
                <div>{ updatedAtDisplay }</div>
              </div>
              
              <Button type="submit" className="btn-secondary" onClick={onSubmit}>
                Enregistrer
              </Button>
            </>
      }
    </FormComponent>
  );
};

export default Form;
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import qs from 'qs';
import _ from 'lodash';

import { productService } from '../../service';
import { mediaObjectService } from '../../../MediaObject/service';

import Spinner from '../../../components/Spinner/Spinner';
import Button from '../../../components/Button/Button';
import FormCategories from '../Form/FormCategories';
import MediaObject from '../../../MediaObject/components/MediaObject';
import Table from '../../../components/Table/Table';
import TableHeader from '../../../components/Table/TableHeader';
import PaginationWithSearch from '../../../components/Pagination/PaginationWithSearch';
import ActionsIconTable from '../../../components/ActionsIconTable/ActionsIconTable';
import Filters from './Filters';
import Tag from '../../../components/Tag/Tag';
import Modal from '../../../components/Modal/Modal';

import * as globalHelpers from '../../../utils/globalHelpers';
import * as productHelpers from '../../helpers';

import * as tagConstants from '../../../components/Tag/constants';
import * as categoryConstants from '../../../Category/constants';
import * as brandConstants from '../../../Brand/constants';
import * as laboratoryConstants from '../../../Laboratory/constants';
import * as productLineConstants from '../../../ProductLine/constants';
import * as productConstants from '../../constants';

import '../../styles/List.scss';

const { API_PATH, PATH } = productConstants;
const { PATH: categoryPath } = categoryConstants;
const { PATH: brandPath } = brandConstants;
const { PATH: laboratoryPath } = laboratoryConstants;
const { PATH: productLinePath } = productLineConstants;

const defaultParams = {
  itemsPerPage: 100,
  page: 1,
  order: {
    name: 'asc',
  }
};

const List = (props) => {
  const { history } = props;
  const [ loading, setLoading ] = useState(false);
  const [ isCategoriesDisplayed, setIsCategoriesDisplayed ] = useState(false);
  const [ isDescriptionDisplayed, setIsDescriptionDisplayed ] = useState(false);
  const [ error, setError ] = useState('');
  const [ products, setProducts ] = useState([]);
  const [ urlsPagination, setUrlsPagination ] = useState({});
  const [ total, setTotal ] = useState(0);
  const [ deletingIds, setDeletingIds ] = useState([]);
  const search = decodeURIComponent(history.location.search);
  const params = search === "" ? defaultParams : qs.parse(search, { ignoreQueryPrefix: true });
  const [ searchParams, setSearchParams ] = useState(params);
  const order = !!searchParams.order ? searchParams.order : defaultParams.order;
  const [ itemsPerPage, setItemsPerPage ] = useState(!!searchParams.itemsPerPage ? searchParams.itemsPerPage : defaultParams.itemsPerPage);
  const [ loadingImport, setLoadingImport ] = useState(false);
  const [ modalPicture, setModalPicture ] = useState({});
  const [ modalCategories, setModalCategories ] = useState({});

  const handleUpdateCategories = async () => {
    try {
      setLoading(true);
      const payload = await productHelpers.parseProductOut({...modalCategories});
      await productService.update(payload.id, payload);
      setError('');
      setLoading(false);
      setModalCategories({});
      const searchParamsString = qs.stringify(searchParams, { ignoreQueryPrefix: true });
      if (history.location.hash === '#' + payload.id) {
        // little hack for making useEffect be called when we update a product
        history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString), hash: ''});
      }
      history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString), hash: '#' +payload.id});
    } catch (e) {
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    }
  };

  const handleChangeProduct = (field, value) => {
    const newProduct = {...modalCategories};

    newProduct[field] = value;

    setModalCategories(newProduct);
  };

  const handleRemoveCatalogs = (field, value) => {
    const valuesSelected = !!modalCategories[field] ? modalCategories[field] : [];
    const filteredValues = valuesSelected.filter((valueSelected) => valueSelected.value !== value.value);

    let newClassifications = [...modalCategories.classifications];
    const classificationsToRemove = newClassifications
      .filter((classification) => classification.catalog === value.value)
      .map((classification) => classification.value)
    newClassifications = newClassifications.filter((classification) => classification.catalog !== value.value);

    let newCategories = [...modalCategories.categories];
    newCategories = newCategories
      .map((cat) => cat.filter((category) => !classificationsToRemove.includes(category.classification)))
      .filter((c) => c.length > 0);

    const newProduct = {
      ...modalCategories,
      classifications: newClassifications,
      catalogs: filteredValues,
      categories: newCategories,
    };

    setModalCategories(newProduct);
  };

  const handleRemoveClassifications = (field, value) => {
    const valuesSelected = !!modalCategories[field] ? modalCategories[field] : [];
    const filteredValues = valuesSelected.filter((valueSelected) => valueSelected.value !== value.value);

    let newCategories = [...modalCategories.categories];
    newCategories = newCategories
      .map((cat) => cat.filter((category) => category.classification !== value.value))
      .filter((c) => c.length > 0);

    const newProduct = {
      ...modalCategories,
      categories: newCategories,
      classifications: filteredValues,
    };

    setModalCategories(newProduct);
  };

  const handleImportFile = async (event) => {
    //TODO: check extension? only images and xlsx ?
    if (event.target.files.length > 50) {
      setError('Trop de fichiers uploadés. Maximum 50 fichiers.');
      return;
    }
    if (event.target.files.length > 0) {
      const { files } = event.target;
      let filesUploaded = [];
      try {
        setLoadingImport(true);
        for (let i = 0; i < files.length; i++) {
          let uploadedFile = await mediaObjectService.createMediaObject();
          await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, files[i]);
          uploadedFile.originalName = files[i].name;
          uploadedFile.name = files[i].name;
          uploadedFile.contentType = files[i].type;
          uploadedFile.contentSize = files[i].size;
          await mediaObjectService.updateMediaObject(uploadedFile.id, uploadedFile);
          filesUploaded.push(uploadedFile.id);
        }
        await productService.uploadImportFiles({'mediaObjects': filesUploaded});
        setLoadingImport(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoadingImport(false);
      }
    }
  }

  const handleSubmit = (newFilters) => {
    let searchParamsString = '';
    const newFiltersQS = {
      page: defaultParams.page,
      itemsPerPage: !!searchParams.itemsPerPage ? searchParams.itemsPerPage : defaultParams.itemsPerPage,
      order: !!searchParams.order ? searchParams.order : defaultParams.order,
      ...newFilters,
    };
    searchParamsString = qs.stringify(newFiltersQS, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString)});
    setSearchParams(newFiltersQS);
  };

  const handleChangeItemsPerPage = (e) => {
    setItemsPerPage(e.target.value);

    const newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams.itemsPerPage = parseInt(e.target.value, 10);
    setSearchParams(newSearchParams);

    let searchParamsString = '';
    searchParamsString = qs.stringify(newSearchParams, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString)});
  };

  const handleChangeOrder = (column, direction) => {
    const newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams.order = {
      [column]: direction
    };
    setSearchParams(newSearchParams);

    let searchParamsString = '';
    searchParamsString = qs.stringify(newSearchParams, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString)});
  };

  const handleDelete = async (itemId) => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      try {
        setLoading(true);
        await productService._delete(itemId);
        const endpoint = `${API_PATH}/`;
        if (!searchParams.itemsPerPage) {
          searchParams.itemsPerPage = defaultParams.itemsPerPage;
        }
        if (!searchParams.order) {
          searchParams.order = defaultParams.order;
        }
        const newProducts = await productService.getAll(endpoint, searchParams);
        setError('');
        setProducts(newProducts["hydra:member"]);
        setUrlsPagination(newProducts["hydra:view"]);
        setTotal(newProducts["hydra:totalItems"]);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setProducts([]);
        setUrlsPagination({});
        setTotal(0);
        setLoading(false);
      }
    }
  };

  const handleSelectedAllForDeleting = () => {
    let newDeletingIds = [ ...deletingIds ];

    if (newDeletingIds.length === products.length) {
      newDeletingIds = [];
    } else {
      newDeletingIds = products.map((product) => product.id);
    }

    setDeletingIds(newDeletingIds);
  };

  const handleSelectedForDeleting = (e) => {
    const { value, checked } = e.target;
    let newDeletingIds = [ ...deletingIds ];

    if (checked) {
      newDeletingIds.push(parseInt(e.target.value, 10));
    } else {
      newDeletingIds = newDeletingIds.filter((deletingId) => deletingId !== parseInt(value, 10));
    }
    setDeletingIds(newDeletingIds);
  };

  const handleDeleteAll = async () => {
    try {
      setLoading(true);
      for (let x = 0; x < deletingIds.length; x++) {
        await productService._delete(deletingIds[x]);
      }

      const endpoint = `${API_PATH}/`;
      if (!searchParams.itemsPerPage) {
        searchParams.itemsPerPage = defaultParams.itemsPerPage;
      }
      if (!searchParams.order) {
        searchParams.order = defaultParams.order;
      }
      const newProducts = await productService.getAll(endpoint, searchParams);
      setError('');
      setProducts(newProducts["hydra:member"]);
      setUrlsPagination(newProducts["hydra:view"]);
      setTotal(newProducts["hydra:totalItems"]);
      setDeletingIds([]);
      //TODO: handle location.hash or not ?
      
      setLoading(false);
    } catch (e) {
      //TODO: load products ?
      setDeletingIds([]);
      setProducts([]);
      setUrlsPagination({});
      setTotal(0);
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    }
  };

  const renderLinksCategories = (categories, itemId) => {
    let levelFamily = 0;
    return categories.map((cat) => {
      levelFamily++;
      return cat.map((c) => {
        return (
          <div className='d-block' key={`${itemId}-${c.id}-${levelFamily}`}>
            <span>
              { `${'-'.repeat(levelFamily)}> `}
              <Link to={`${categoryPath}show/${encodeURIComponent(c.id)}`}>
                {c.name}
              </Link>
            </span>
          </div>
        );
      });
    });
  };

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();

    if (mounted) {
      const search = decodeURIComponent(history.location.search);
      if (qs.stringify(searchParams, { addQueryPrefix: true }) !== search) {
        setSearchParams(qs.parse(search, { ignoreQueryPrefix: true }))
      } else {
        setLoading(true);
        const endpoint = `${API_PATH}/`;
        if (!searchParams.itemsPerPage) {
          searchParams.itemsPerPage = defaultParams.itemsPerPage;
        }
        if (!searchParams.order) {
          searchParams.order = defaultParams.order;
        }
        productService.getAll(endpoint, searchParams, {
          signal: controller.signal
        }).then((newProducts) => {
          setError('');
          setProducts(newProducts["hydra:member"]);
          setUrlsPagination(newProducts["hydra:view"]);
          setTotal(newProducts["hydra:totalItems"]);
          setLoading(false);

          if (history.location.hash) {
            const id = history.location.hash.replace('#', '');
            if (!!id) {
              const found = newProducts["hydra:member"].find((newProduct) => newProduct.id.toString() === id);
              if (found) {
                const ofTop = document.getElementById(id).offsetTop;
                document.getElementById('block-table-products').scrollTop = ofTop - 65;
              }
            }
          }
        }).catch((e) => {
          setError(!!e && !!e.message ? e.message : e);
          setProducts([]);
          setUrlsPagination({});
          setTotal(0);
          setLoading(false);
        });
      }
    }

    return () => {
      mounted = false;
      controller?.abort();
    }
  }, [searchParams, history.location.search, history.location.hash])

  const dataBlock = products && products.map((item) => {
    let shortDescription = (item["shortDescription"].length > 0 && item["shortDescription"].slice(0, 300));
    if (item['shortDescription'].length > 300) {
      shortDescription += '...';
    }

    let picturesBlock = <div className="center-tag"><div className="mw-3rem"><Tag style={tagConstants.QUATERNARY} text="Non" /></div></div>;
    if (!!item['productFiles'] && item['productFiles'].length > 0) {
      picturesBlock = item['productFiles'].map((picture, index) => {
        const classNameProductFile = item['productFiles'].length > 1 && index < item['productFiles'].length ? 'mb-2' : '';
        return (
          <div onClick={() => setModalPicture(picture.mediaObject)} key={`media-object-${picture.name}-${index}`} className={classNameProductFile}>
            <MediaObject logo={picture.mediaObject} />
          </div>
        );
      });
      picturesBlock = <div className="pictures overflow-div scroll-custom">{picturesBlock}</div>
    }

    let laboratoryLink = null;
    if (!!item.laboratory && !!item.laboratory.name && !!item.laboratory.id) {
      laboratoryLink = <Link to={`${laboratoryPath}show/${item.laboratory.id}`}>{item.laboratory.name}</Link>;
    }

    let brandLink = null;
    if (!!item.brand && !!item.brand.name && !!item.brand.id) {
      brandLink = <Link to={`${brandPath}show/${item.brand.id}`}>{item.brand.name}</Link>;
    }

    let productLineLink = null;
    if (!!item.productLine && !!item.productLine.name && !!item.productLine.id) {
      productLineLink = <Link to={`${productLinePath}show/${item.productLine.id}`}>{item.productLine.name}</Link>;
    }

    const isSelectedForDeleting = deletingIds.find((deletingId) => parseInt(deletingId, 10) === item.id);

    return (
      <tr key={'product-' + item["id"]} id={item.id}>
        <th scope="row" className="id">
          <div className="d-flex justify-content-center align-items-center overflow-div">
            <input className="form-check-input" type="checkbox" onChange={handleSelectedForDeleting} value={item['id']} checked={!!isSelectedForDeleting} />
          </div>
        </th>
        <td>{ laboratoryLink }</td>
        <td>{ brandLink }</td>
        <td>{ productLineLink }</td>
        <td>{ item["name"] }</td>
        <td>{ item["EAN13"] }</td>
        <td>{ item["ACL13"] }</td>
        <td>{ item["CIP7"] }</td>
        { isCategoriesDisplayed && (
          <td className="categories">
            <div className="block">
              <div className="center-line">
                <span onClick={async () => setModalCategories(await productHelpers.parseProductIn(_.cloneDeep(item)))}>
                  <i className="fa fa-pencil icon-actions" aria-hidden="true" />
                  <i className="sr-only">Editer catégories</i>
                </span>
              </div>
              <div className="overflow-div scroll-custom">
                { renderLinksCategories(item["categories"], item.id) }
              </div>
            </div>
          </td>
        )}
        { isDescriptionDisplayed && (
          <td>
            {item["shortDescription"].length > 0 && (
              <div className="overflow-div description scroll-custom" dangerouslySetInnerHTML={{
                __html: shortDescription
              }}></div>
            )}
          </td>
        )}
        <td>{ picturesBlock }</td>
        <ActionsIconTable
          item={item}
          onDelete={handleDelete}
        />
      </tr>
    )
  });

  const tableHeaders = [
    {
      field: 'id',
      sortable: false,
      className: 'th-id',
      translation: (
        <div className="d-inline-flex">
          <input className="form-check-input" type="checkbox" onChange={handleSelectedAllForDeleting} checked={deletingIds.length === products.length} />
        </div>
      ),
    },
    {
      field: 'laboratory',
      sortable: false,
      translation: 'Laboratoire',
    },
    {
      field: 'brand',
      sortable: false,
      translation: 'Marque',
    },
    {
      field: 'product_line',
      sortable: false,
      translation: 'Gamme',
    },
    {
      field: 'name',
      sortable: true,
      translation: 'Nom',
    },
    {
      field: 'EAN13',
      sortable: true,
      translation: 'EAN 13',
    },
    {
      field: 'ACL13',
      sortable: true,
      translation: 'ACL 13',
    },
    {
      field: 'CIP7',
      sortable: true,
      translation: 'CIP 7',
    },
    {
      field: 'productFiles',
      sortable: false,
      translation: 'Images',
      className: 'text-center',
    },
    {
      field: 'actions',
      sortable: false,
      translation: 'Actions',
      colSpan: 3,
    },
   ];

  if (isCategoriesDisplayed) {
    tableHeaders.splice(8, 0, {
      field: 'categories',
      sortable: false,
      translation: 'Catégories',
      className: 'categories',
    });
  }
  if (isDescriptionDisplayed) {
    tableHeaders.splice((isCategoriesDisplayed ? 9 : 8), 0, {
      field: 'shortDescription',
      sortable: false,
      translation: 'Description',
    });
  }

  return (
   <div className="list-products d-flex flex-column h-100 mh-90vh justify-content-between">
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      <div className="d-flex justify-content-between">
        <div className="d-inline-flex">
          <div className="me-3">
            <div>
              <label htmlFor="change_items_per_page">Éléments / page : </label>
              <select
                name="change_items_per_page"
                onChange={handleChangeItemsPerPage}
                value={itemsPerPage}
                className="ms-2"
              >
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
            { globalHelpers.generateTotalPagination(itemsPerPage, searchParams.page, total) }
          </div>
          <div>
            <div className="form-check form-switch">
              <input 
                className="form-check-input"
                type="checkbox"
                checked={isCategoriesDisplayed}
                onChange={() => setIsCategoriesDisplayed(!isCategoriesDisplayed)}
              />
              <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Afficher les catégories</label>
            </div>
            <div className="form-check form-switch">
              <input 
                className="form-check-input"
                type="checkbox"
                checked={isDescriptionDisplayed}
                onChange={() => setIsDescriptionDisplayed(!isDescriptionDisplayed)}
              />
              <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Afficher les descriptions courtes</label>
            </div>
          </div>
        </div>
        <div className="buttons">
          <div className="d-inline-flex buttons-block">
           { deletingIds.length > 0 && (
              <Button className="btn-danger btn-delete-all" onClick={handleDeleteAll} title="Tout supprimer">
                <i className="fa fa-trash fa-2x" aria-hidden="true" />
                <i className="sr-only">Tout supprimer</i>
              </Button>
            )}
            <Button className="btn-secondary" onClick={() => history.push(`${PATH}create`)} title="Ajouter">
              <i className="fa fa-plus-circle fa-2x" aria-hidden="true" />
              <i className="sr-only">Ajouter</i>
            </Button>
            <div className="import">
              <label htmlFor="upload-btn" className="btn btn-primary btn-sm center-block btn-file btn-import">
                { loadingImport
                    ? <Spinner />
                    :
                      <>
                        <i title="Importer fichier" className="fa fa-upload fa-2x" aria-hidden="true" />
                        <i className="sr-only">Importer</i>
                        <input type="file" id="upload-btn" name="import" style={{display: "none"}} onChange={handleImportFile} multiple />
                      </>
                }
              </label>
            </div>
            <Button onClick={() => history.push('/products/imports')}>
              <i className="fa fa-list-ol fa-2x" aria-hidden="true" />
              <i className="sr-only">Liste des imports</i>
            </Button>
          </div>
        </div>
      </div>

      {
        Object.keys(modalPicture).length > 0 && (
          <Modal showFooter onClose={() => setModalPicture({})}>
            <MediaObject logo={modalPicture} />
          </Modal>
        )
      }

      {
        Object.keys(modalCategories).length > 0 && (
          <Modal
            showFooter
            onClose={() => setModalCategories({})}
            onSave={handleUpdateCategories}
            loading={loading}
          >
            <div className="container">
              <div className="row">
                <FormCategories
                  classifications={modalCategories.classifications}
                  categories={modalCategories.categories}
                  catalogs={modalCategories.catalogs}
                  onChangeNameField={handleChangeProduct}
                  onRemoveCatalogs={handleRemoveCatalogs}
                  onRemoveClassifications={handleRemoveClassifications}
                />
              </div>
            </div>
          </Modal>
        )
      }

      <div className="mb-3 overflow-hidden block-table-filters">
        <Filters
          filters={searchParams}
          onSubmit={handleSubmit}
        />
      
        <div id="block-table-products" className="w-100 mw-100 overflow-auto scroll-custom mb-3">
          {
            loading
              ?
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <Spinner />
                </div>
              :
                (
                  <Table>
                    <TableHeader
                      headers={tableHeaders}
                      order={order} 
                      onChangeOrder={handleChangeOrder}  
                    />
                    <tbody>
                      { 
                        dataBlock.length === 0
                          ?
                            (<tr>
                              <td colSpan={10}>
                                <div className="d-flex justify-content-center align-items-center minh-50vh">
                                  <span>Pas de résultat.</span>
                                </div>
                              </td>
                            </tr>)
                          :
                            dataBlock
                      }
                    </tbody>
                  </Table>
                )
          }
          
        </div>
      </div>
      <PaginationWithSearch 
        view={urlsPagination} 
        filters={searchParams}  
      />
    </div>
  );
}

export default List;
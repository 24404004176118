export const initialProduct = {
  name: '',
  catalogs: [],
  classifications: [],
  categories: [],
  laboratory: '',
  brand: '',
  productLine: '',
  EAN13: '',
  ACL13: '',
  CIP7: '',
  shortDescription: '',
  description: '',
  urlVideos: [
    ''
  ],
  advice: '',
  caution: '',
  composition: '',
  notice: '',
  weight: {
    value: 0,
    unit: '',
  },
  height: {
    value: 0,
    unit: '',
  },
  depth: {
    value: 0,
    unit: '',
  },
  width: {
    value: 0,
    unit: '',
  },
  //generalFeatures: [],
  productFiles: []
};

export const API_PATH = "api/products";
export const API_PATH_IMPORTS = "api/product_imports";
export const PATH = "/products/";
export const PATH_IMPORTS = "/products/imports/";
import React, { useState } from 'react';

import ExpansionPanelFilters from '../../../components/ExpansionPanel/ExpansionPanelFilters';
import InputSearchLaboratory from '../../../components/Input/InputSearchLaboratory';

const Filters = (props) => {
  const { filters, onSubmit } = props;
  const [ name, setName ] = useState(!!filters.name ? filters.name : '');
  const [ laboratory, setLaboratory ] = useState(!!filters.laboratory ? filters.laboratory : '');

  const handleChangeLaboratory = (field, value) => {
    setLaboratory(value);
  }

  const handleRemoveLaboratory = () => {
    setLaboratory('');
  }

  const handleSubmit = () => {
    const filters = {};
    if (name !== '') {
      Object.assign(filters, { name: name.trim() });
    }
    if (laboratory !== '') {
      Object.assign(filters, { laboratory: laboratory });
    }
    
    onSubmit(filters);
  };

  const handleClear = () => {
    if (name !== '') setName('');
    if (laboratory !== '') handleRemoveLaboratory();

    onSubmit({});
  };

  return (
    <ExpansionPanelFilters 
      title='Filtres'
      active={false}
      onSubmit={handleSubmit}
      onClear={handleClear}
    >
      <div className="form-group">
        <label
          htmlFor="brand_name"
          className="form-control-label"
        >
          Nom
        </label>
        <input
          type="text"
          id='brand_name'
          name="name"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
      </div>

      <InputSearchLaboratory
        //value={laboratory}
        value={typeof laboratory === 'string' ? laboratory : (laboratory && laboratory.label)}
        onChange={handleChangeLaboratory}
        onSelect={handleChangeLaboratory}
        onClear={handleRemoveLaboratory}
        field='laboratory'
      />
    </ExpansionPanelFilters>
  );
};

export default Filters;
import React from "react";
import { Route } from "react-router-dom";

import List from "../Category/components/List/List.jsx";
import Create from "../Category/components/Create.jsx";
import Show from "../Category/components/Show.jsx";
import Edit from "../Category/components/Edit.jsx";

const routes = [
  <Route path="/categories/create" component={Create} exact key="create" />,
  <Route path="/categories/edit/:id" component={Edit} exact key="edit" />,
  <Route path="/categories/show/:id" component={Show} exact key="show" />,
  <Route path="/categories/" component={List} exact strict key="list" />,
];

export default routes;

import React from "react";
import { Route } from "react-router-dom";

import List from "../Brand/components/List/List.jsx";
import Create from "../Brand/components/Create.jsx";
import Show from "../Brand/components/Show.jsx";
import Edit from "../Brand/components/Edit.jsx";

const routes = [
  <Route path="/brands/create" component={Create} exact key="create" />,
  <Route path="/brands/edit/:id" component={Edit} exact key="edit" />,
  <Route path="/brands/show/:id" component={Show} exact key="show" />,
  <Route path="/brands/" component={List} exact strict key="list" />,
];

export default routes;
